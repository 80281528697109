/*
 * 
 * Reset and base styles
 *
 */


* {
	padding: 0px;
	margin: 0px;
	border: none;
	-webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px #DDDDDF inset !important;
}

/* Links */

a, a:link, a:visited  {
    text-decoration: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


a:hover  {
    text-decoration: none;
}

/* Common */

aside, nav, footer, header, section, main {
	display: block;
}

h1, h2, h3, h4, h5, h6, p {
    font-size: inherit;
	font-weight: inherit;
}

ul, ul li {
	list-style: none;
}

html, body{
	height: 100%;
}

img {
	vertical-align: top;
}

img, svg {
	max-width: 100%;
	height: auto;
}

address {
  font-style: normal;
}

/* Form */

input, textarea, button, select {
	font-family: inherit;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
}

input::-ms-clear {
	display: none;
}

button, input[type="submit"] {
    display: inline-block;
    box-shadow: none;
    background-color: transparent;
    background: none;
    cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
    outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

label {
	cursor: pointer;
}

legend {
	display: block;
}

@media (hover: none){
	.no-active-wrap-button{
	  opacity: 0;
	  pointer-events: none;
	}
}