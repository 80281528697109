@font-face {
    font-family: 'Roboto Flex';
    src: url('../media/fonts/RobotoFlex-Variable.woff2') format('woff2'),
         url('../media/fonts/RobotoFlex-Variable.woff') format('woff');
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('../media/fonts/RobotoMono-Variable.woff2') format('woff2'),
         url('../media/fonts/RobotoMono-Variable.woff') format('woff');
    font-style: normal;
}

:root{
    --second-family: 'Roboto Flex';
    --font-family: 'Roboto Mono'
}

iframe{
    display: none;
}

body{
    background-color: #fff;
}

#app{
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 550px;
    margin: 0px auto;
    height: calc(100dvh - 64px);
    background-color: #F5F5F5;
    border-radius: 16px;
    overflow: auto;
    height: 100%;
}

.wrapMainForm{
    width: 100%;
    max-width: inherit;
}

/* ::-webkit-scrollbar {
    display: none; 
    width: 0; 
    background: transparent;
} */
  

#app::-webkit-scrollbar{
    width: 0;
    height: 0;
    display: none; 
    background: transparent;
}
.wrapMainForm{
    padding: 24px 0 0 0;
    display: flex;
    flex-direction: column;
    width: inherit;
}

h1{
    padding: 0 16px;
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 42px;
    line-height: 96%;
    letter-spacing: -0.01em;
    color: #060615;
}

.wrapMainForm > p{
    padding: 0 16px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 136%;
    letter-spacing: -0.02em;
    color: #82828a;
    margin-top: 16px;
}

.main_form{
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    gap: 24px;
    height: -webkit-fill-available;
    max-width: inherit;
}

.wrap_input{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.wrap_input label{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 86%;
    text-transform: uppercase;
    color: #060615;
    transition: color .2s ease-out;
}

.wrap_input input{
    border-radius: 18px;
    padding: 28px 24px;
    width: 100%;
    height: 68px;
    background-color: #E9E9EA;

    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 86%;
    text-transform: uppercase;
    color: #8E8E94;

    transition: color .2s ease-out, background-color .2s ease-out;
}

.wrap_input input::placeholder{
    color: #8E8E94;
    transition: color .2s ease-out;
}

.wrap_input input:not(:placeholder-shown){
    color: #060615;
    background-color: #DDDDDF;
}

.wrapInput_radio input{
    display: none;
}

.wrapConnectButt{
    display: flex;
    flex-direction: row;
    gap: 8px;   
}

.wrapConnectButt .wrapInput_radio{
    display: flex;
}

.wrap_input .wrapInput_radio label{
    transition: color .2s ease-out, background-color .2s ease-out, opacity .2s ease-out;
    display: flex;
    border-radius: 1000px;
    padding: 16px;
    background-color: #E9E9EA;
}
.wrapConnectButt .wrapInput_radio:has(input:checked) label{
    color: #fff;
    background-color: #060615;
}


/* .wrap_appr_button{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: auto;
    padding-bottom: 16px;
    position: fixed;
    width: 100%;
    bottom: 32px;
    background-color: #F5F5F5;
    border-radius: 0 0 16px 16px;
    padding: 32px 16px 16px 16px;
    max-width: inherit;
} */

.wrap_appr_button > p,
.wrap_button_inf > p{
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 116%;
    letter-spacing: -0.02em;
    text-align: center;
    color: rgba(6, 6, 21, 0.4);
}

.wrap_appr_button > p a,
.wrap_button_inf > p a{
    color: #2A59FF;
    display: inline-flex;
    position: relative;
    overflow: hidden;
    transition: opacity .2s ease-out;
}

.wrap_appr_button > p a::before,
.wrap_button_inf > p a::before{
    content: "";
    border-bottom: 1px solid #2A59FF;
    position: absolute;
    inset: 0;
    pointer-events: none;
    transition: translate .2s ease-out;
}


.wrap_input.error > label,
.wrap_input.error input,
.wrap_input.error input::placeholder{
    color: #FF5353;
}

.wrap_input:has(.wrapConnectButt) > label{
    cursor: auto;
}


.wrap_input_diapazon{
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
}
.wrap_input_diapazon input{
    width: 100%;
}






@media (hover:hover){
    
    .wrap_appr_button > p a:hover::before,
    .wrap_button_inf > p a:hover::before{
        translate: -105% 0;
    }

    .wrap_input:hover input{
        background-color: #DDDDDF;
    }

    .wrap_input .wrapInput_radio label:hover{
        opacity: .8;
    }
    
}


.wrap_appr_button > p a:active,
.wrap_button_inf > p a:active{
    opacity: .6;
}

.wrap_input .wrapInput_radio label:active{
    opacity: .6;
}

.wrapPolicyModal{
    width: 375px;
}

@media (max-width: 490px) {
    body{
        background-color: #fff;
    }
    #app{
        width: 100%;
        margin: 0;
        height: 100dvh;
        border-radius: 0;
    }
    .wrap_button,
    .wrap_appr_button,
    .wrap_button_step{
        bottom: 0;
        width: 100%;
    }
    .wrapPolicyModal{
        width: 100%;
    }
}